.auth-page,
.private-page {
	width: 100%;
	min-height: 100vh;
}

.private-page .ant-layout-header {
	background-color: #fff;
	padding: 0px;
}
.auth-page .page-content,
.private-page .page-content {
	padding: 20px;
}
.auth-page .ant-layout,
.private-page .ant-layout {
	height: 100%;
}
.auth-page .ant-layout-footer > div,
.private-page .ant-layout-footer > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.auth-page .ant-layout-footer > div a,
.private-page .ant-layout-footer > div a {
	cursor: pointer;
}
.ant-layout-footer {
	padding: 5px 50px;
}
/* Auth Layout */
.auth-page .page-header {
	width: 100%;
	height: 50px;
	background: #096dd9;
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
}
.auth-page .page-header h1 {
	color: white;
	margin: 0px;
}

/* Private Layout */
.private-page .ant-layout-sider .logo {
	height: 32px;
	background: rgba(255, 255, 255, 0.2);
	margin: 16px;
}
.private-page .ant-layout-sider-children {
	overflow-y: auto;
}
.private-page .ant-layout-header {
	background-color: #fff;
	padding: 0px;
}
.private-page .ant-layout-header > div {
	display: flex;
	justify-content: space-between;
	padding: 0px 20px;
	align-items: center;
}
.private-page .ant-layout-header > div > .page-title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}
.private-page .ant-layout-header > div > .page-title > button {
	padding-left: 0px;
}
.private-page .ant-layout-header > div > .page-title > h2 {
	margin: 0px;
}
.private-page .ant-layout-footer > div {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.private-page .ant-layout-footer > div a {
	cursor: pointer;
}

/* Antd Table */
.ant-table-thead
	> tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td,
.ant-table-tbody
	> tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
	> td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
	background-color: white !important;
	color: rgb(110, 110, 110) !important;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
	overflow-wrap: initial;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
	padding: 10px;
}
div.ant-table-wrapper:not(.budget-utilizations-table) .ant-table-thead > tr > th,
div.ant-table-wrapper:not(.incremental-timeline) .ant-table-thead > tr > th,
div.ant-table-wrapper:not(.yoy-summary) .ant-table-thead > tr > th,
div.ant-table-wrapper:not(.position-req-report) .ant-table-thead > tr > th {
	background: #f2f2f2;
	font-weight: bold;
}
div.budget_approval_workflow tr > th {
	background-color: red;
}

div.budget-utilizations-table tr > th,
div.incremental-timeline tr > th,
div.position-req-report tr > th,
div.yoy-summary tr > th {
	word-wrap: break-word;
	/* word-break: break-all; */
	text-align: center;
	color: white;
	font-weight: bold;
}
div.budget_approval_workflow .ant-table-tbody > tr:nth-child(2) > td:first-child,
div.budget_approval_workflow .ant-table-tbody > tr:nth-child(3) > td:first-child,
div.budget_approval_workflow .ant-table-tbody > tr:nth-child(4) > td:first-child,
div.budget_approval_workflow .ant-table-tbody > tr:nth-child(5) > td:first-child {
	font-weight: bold;
	color: black;
}

/* div.budget_approval_workflow .ant-table-thead > tr:first-child > th:nth-child(1),
div.budget_approval_workflow .ant-table-thead > tr:first-child > th:nth-child(2),
div.budget_approval_workflow .ant-table-thead > tr:first-child > th:nth-child(3),
div.budget_approval_workflow .ant-table-thead > tr:first-child > th:nth-child(4)
{
color: white;
background-color: #7614a7;
} */

div.budget-utilizations-table .ant-table-thead > tr:first-child > th:first-child,
div.budget-utilizations-table .ant-table-thead > tr:first-child > th:last-child,
div.incremental-timeline .ant-table-thead > tr:first-child > th:first-child,
div.incremental-timeline .ant-table-thead > tr:first-child > th:last-child {
	background-color: #273371;
}

div.budget-utilizations-table .ant-table-thead > tr:first-child > th:nth-child(2),
div.budget-utilizations-table .ant-table-thead > tr:first-child > th:nth-child(3),
div.budget-utilizations-table .ant-table-thead > tr:first-child > th:nth-child(4),
div.budget-utilizations-table .ant-table-thead > tr:first-child > th:nth-child(5),
div.budget-utilizations-table .ant-table-thead > tr:first-child > th:nth-child(6),
div.incremental-timeline .ant-table-thead > tr:first-child > th:nth-child(2),
div.incremental-timeline .ant-table-thead > tr:first-child > th:nth-child(3),
div.incremental-timeline .ant-table-thead > tr:first-child > th:nth-child(4),
div.incremental-timeline .ant-table-thead > tr:first-child > th:nth-child(5),
div.incremental-timeline .ant-table-thead > tr:first-child > th:nth-child(6),
div.incremental-timeline .ant-table-thead > tr:first-child > .H1,
div.incremental-timeline .ant-table-thead > tr:first-child > .H2,
div.position-req-report .ant-table-thead > tr:first-child > th,
div.yoy-summary .ant-table-thead > tr:first-child > th {
	background-color: #3f4d98;
}

.colored-bg,
tr:hover .colored-bg {
	background-color: #6473c7 !important;
	color: white !important;
}

.colored-bg-dark,
tr:hover .colored-bg-dark {
	background-color: #273371 !important;
	color: white !important;
}
div.budget-utilizations-table .ant-table-thead > tr:nth-child(2) > th,
div.incremental-timeline .ant-table-thead > tr:nth-child(2) > th,
div.position-req-report .ant-table-thead > tr:nth-child(2) > th,
div.yoy-summary .ant-table-thead > tr:nth-child(2) > th {
	background-color: #6473c7;
}

div.budget-utilizations-table .ant-table-thead > tr:nth-child(3) > th,
div.incremental-timeline .ant-table-thead > tr:nth-child(3) > th {
	background-color: #bdc3ec;
	color: black;
}
div.budget-utilizations-table .ant-table-thead > tr:nth-child(4) > th,
div.incremental-timeline .ant-table-thead > tr:nth-child(4) > th {
	background-color: #bdc3ec;
	color: black;
}

div.budget-utilizations-table .ant-table-tbody > tr > td,
div.incremental-timeline .ant-table tfoot > tr > td {
	padding: 2px;
}

div.budget-utilizations-table .ant-table-tbody tr td {
	text-align: right;
}
div.incremental-timeline .ant-table-tbody tr td,
div.position-req-report .ant-table-tbody tr td,
div.yoy-summary .ant-table-tbody tr td,
div.transition-analysis .ant-table-tbody tr td,
div.transition-analysis .ant-table-thead tr th {
	text-align: center;
}
div.budget-utilizations-table .ant-table-tbody tr td:nth-child(1),
div.budget-utilizations-table .ant-table-tbody tr td:nth-child(2) {
	text-align: left;
}

.textDecoration > span {
	text-decoration: underline;
}

/* Workforce table */
/* div.ant-table-wrapper:not(.workforce-plan-table) .ant-table-thead > tr > th {
	background: #f2f2f2;
	font-weight: bold;
} */
div.workforce-plan-table tr > th {
	word-wrap: break-word;
	/* word-break: break-all; */
	text-align: center;
	color: white;
	font-weight: bold;
}

div.workforce-plan-table .ant-table-thead > tr:first-child > th {
	background-color: #3f4d98;
}

div.workforce-plan-table .ant-table-thead > tr:first-child > th:first-child,
div.workforce-plan-table .ant-table-thead > tr:first-child > th:last-child {
	background-color: #273371;
}

/* div.workforce-plan-table .ant-table-thead > tr:first-child > th:nth-child(2),
div.workforce-plan-table .ant-table-thead > tr:first-child > th:nth-child(3),
div.workforce-plan-table .ant-table-thead > tr:first-child > th:nth-child(4),
div.workforce-plan-table .ant-table-thead > tr:first-child > th:nth-child(5),
div.workforce-plan-table .ant-table-thead > tr:first-child > th:nth-child(6) {
	background-color: #3f4d98;
} */
div.workforce-plan-table .ant-table-thead > tr:nth-child(2) > th {
	background-color: #6473c7;
}

div.workforce-plan-table .ant-table-thead > tr:nth-child(3) > th {
	background-color: white;
	color: black;
}

div.workforce-plan-table .ant-table-tbody > tr > td,
div.workforce-plan-table .ant-table tfoot > tr > td {
	padding: 2px;
}

div.workforce-plan-table .ant-table-tbody tr td {
	text-align: center;
}
div.workforce-plan-table .ant-table-tbody tr td:nth-child(1),
div.workforce-plan-table .ant-table-tbody tr td:nth-child(2),
div.workforce-plan-table .ant-table-tbody tr td:nth-child(3),
div.workforce-plan-table .ant-table-tbody tr td:nth-child(4) {
	text-align: center;
}

/* Tooltip */
.ant-tooltip-inner {
	text-transform: capitalize;
}

/* Popover */
.ant-popover-title {
	text-transform: capitalize;
}

/* Collapse */
.ant-collapse-content-box {
	overflow: auto;
}

.ant-layout-sider .logo__short,
.ant-layout-sider.ant-layout-sider-collapsed .logo__long {
	display: none;
}

.ant-layout-sider .logo__long,
.ant-layout-sider.ant-layout-sider-collapsed .logo__short {
	display: block;
}

.fitment__status-box {
	display: flex;
	align-items: center;
	gap: 5px;
	text-transform: capitalize;
}

.fitment__status-box > .fitment__status {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.fitment__status-box > .fitment__status.red {
	background: #d82626;
}
.fitment__status-box > .fitment__status.green {
	background: green;
}
.fitment__status-box > .fitment__status.orange {
	background: orange;
}
.fitment__status-box > .fitment__status.gray {
	background: gray;
}

/* Tree Table */

.cp_tree-table {
	font-size: 14px;
	color: #040402;
	background: #fff;
	border: 1px solid rgba(55, 53, 47, 0.2);
	font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
		sans-serif;
}

/* Header */

.cp_tree-table .cp_tree-table_header {
	color: rgba(55, 53, 47, 0.6);
	background: rgba(242, 241, 238, 0.6);
	border-bottom: 1px solid rgba(55, 53, 47, 0.09);
}

/* Employees & Expenses */

.cp_tree-table .cp_tree-table_header-cell > .t-right {
	width: 100%;
	text-align: right;
}

/* Cells */

.cp_tree-table .cp_tree-table_cell,
.cp_tree-table .cp_tree-table_header-cell {
	display: flex;
	align-items: center;
	padding: 0 10px;
}

/* Editable cell */

.cp_tree-table .cp_tree-table_cell > input {
	font-size: 14px;
	outline: none;
	width: 100%;
	height: 100%;
	padding: 5px 8px;
	box-sizing: border-box;
	box-shadow: inset 0 0 0 1px rgba(55, 53, 47, 0.16);
	border: 1px solid transparent;
}

.cp_tree-table .cp_tree-table_cell > input:hover {
	box-shadow: inset 0 0 0 1px #0f55eb;
}

.cp_tree-table .cp_tree-table_cell > input:focus {
	background: #edf6fc;
	box-shadow: inset 0 0 0 1px #0f55eb;
}

.bh_steps {
	margin: 10px;
}

/* Index cell  */

.cp_tree-table .cp_tree-table_cell .toggle-button {
	position: relative;
	display: inline-block;
	border: none;
	height: 14px;
	vertical-align: baseline;
	padding: 0 5px 0 0;
	margin: 0;
	cursor: pointer;
	background-color: transparent;
	outline: none;
}

/* .cp_tree-table .cp_tree-table_cell .toggle-button::after {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #040402 transparent transparent transparent;
  padding: 0;
  vertical-align: bottom;
} */

.cp_tree-table .cp_tree-table_cell .toggle-button .toggle-button-icon {
	vertical-align: middle;
}
.cp_tree-table .cp_tree-table_cell .toggle-button + span {
	vertical-align: middle;
}

.cp_tree-table .cp_tree-table_cell > .without-children > span {
	padding-left: 20px;
}

.cp_tree-table .cp_tree-table_cell > .with-children > span {
	font-weight: 600;
}

/* Employees cell */

.cp_tree-table .cp_tree-table_cell > .employees-cell {
	width: 100%;
	text-align: right;
}

/* Expenses cell */

.cp_tree-table .cp_tree-table_cell > .expenses-cell {
	font-family: 'SF Mono', 'Segoe UI Mono', 'Roboto Mono', Menlo, Courier, monospace;
	font-weight: 400;
	width: 100%;
	text-align: right;
	padding-right: 10px;
}

/* CP React Table */
.cp_tree-table_row{
	height: auto!important;
}

.cp_tree-table {
	border-width: 0px;
}

.cp_tree-table_header {
	height: auto !important;
	border-width: 0px !important;
}

.cp_tree-table_header-cell {
	padding: 10px !important;
	color: black;
	background: #f2f2f2;
	font-weight: bold;
}

.cp_tree-table_header > .cp_tree-table_header-cell:first-child,
.cp_tree-table_viewport .cp_tree-table_cell:first-child {
	flex-basis: 380px !important;
}
/* budget-analysis cp_tree_table */
.cp_tree-table.budget-analysis-table .cp_tree-table_row .cp_tree-table_cell:nth-child(2),
.cp_tree-table.budget-analysis-table .cp_tree-table_row .cp_tree-table_cell:nth-child(3),
.cp_tree-table.budget-analysis-table .cp_tree-table_row .cp_tree-table_cell:nth-child(4) {
	display: inline-flex;
	justify-content: flex-end;
}
.cp_tree-table.budget-analysis-table .cp_tree-table_row .cp_tree-table_cell:last-child {
	display: inline-flex;
	justify-content: center;
}

/* Disabled Checkbox */
.ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #f5f5f5 !important;
}

/* Docusign Status */
.docusign-status {
	width: 13px !important;
	height: 13px;
	border-radius: 50%;
	background-color: #dad7d7;
	margin: 0px 10px;
	display: block;
	flex: none;
}
.docusign-status.Sent {
	background-color: orange !important;
}
.docusign-status.Completed {
	background-color: green !important;
}
.docusign-status.Declined {
	background-color: red !important;
}

/* Setting css */
.list-style {
	list-style: none;
}

.setting-outer-layer {
	display: flex;
	justify-content: space-around;
}

.list-head {
	margin: 0;
}
.list-sub {
	padding-bottom: 1em;
}
li {
	padding-bottom: 0.3em;
}
.setting-list {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.padding-down {
	padding-bottom: 80px;
}

.fitment__status-box {
	display: flex;
	align-items: center;
	gap: 5px;
	text-transform: capitalize;
}

.fitment__status-box > .fitment__status {
	width: 10px;
	height: 10px;
	border-radius: 50%;
}
.fitment__status-box > .fitment__status.red {
	background: #d82626;
}
.fitment__status-box > .fitment__status.green {
	background: green;
}
.fitment__status-box > .fitment__status.orange {
	background: orange;
}
.fitment__status-box > .fitment__status.gray {
	background: gray;
}

/* orgChart */

.orgchart-container{
overflow: visible;
border: none;
}

.orgchart {
	background-image:none;
}

.text-capitalize{
	text-transform: capitalize;
}
